<template>
  <div class="modern-color-theme text-left font-poppins flex flex-col gap-2">
    <VSLabel v-if="props.label" :tooltip="props.labelTooltip" :for="id">{{ props.label }}</VSLabel>
    <button
      :id="id"
      ref="buttonElement"
      type="button"
      class="flex items-center w-full rounded-md px-3 py-2 ring-1 ring-inset text-sm leading-5 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600"
      :class="{
        'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
        'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled
      }"
      :disabled="props.disabled"
      :name="props.name"
      @click="menuOpen = menuOpen ? false : !props.readonly"
    >
      <slot />
      <div class="ml-auto flex gap-2">
        <VButton v-if="props.allowEmpty && !(props.disabled || props.readonly) && props.isAnySelected" raw @click="reset">
          <VIcon size="sm" name="Solid/x-mark" />
        </VButton>
        <VIcon size="sm" name="Solid/chevron-down" />
      </div>
    </button>
    <VTeleport v-if="menuOpen" to="body">
      <ul ref="menuElement" :data-parent-id="id" class="modern-color-theme font-poppins fixed z-[1001] w-full my-1 rounded-md bg-neutral-100 shadow-sm ring-1 ring-inset ring-neutral-250 flex flex-col p-1 overflow-y-auto max-h-72" :style="position">
        <li v-if="props.search || props.open" :class="{ 'mb-1': searchCollectionFlat.length > 0 }">
          <VInput v-model="searchModel" :placeholder="props.searchPlaceholder" focus @keydown.esc="menuOpen = false" @keydown.enter.prevent="add">
            <template #suffix>
              <button v-if="searchModel && props.open" :disabled="!canAdd" type="button" class="flex items-center justify-center" @click="add">
                <VIcon color="neutral" name="Solid/add-box" />
              </button>
              <VIcon v-else color="neutral" name="Solid/search" />
            </template>
          </VInput>
        </li>
        <VLazyDisplayable :collection="searchCollectionFlat" :size="25" loader-container="li">
          <template #default="{ item: option }">
            <li :style="{ paddingLeft: `${2 * option._nestingLevel}em` }">
              <template v-if="'type' in option">
                <template v-if="option.type === 'group'">
                  <template v-if="props.multiple">
                    <button
                      class="px-4 pl-3 w-full text-sm leading-5 rounded-md flex gap-2 items-center whitespace-nowrap cursor-pointer hover:bg-primary-150 text-neutral-850"
                      :title="option.label"
                      @click.prevent="toggleItemsInGroup(option)"
                    >
                      <VCheckbox
                        :model-value="countSelectedItemsInGroup(option) > 0"
                        :indeterminate="countSelectedItemsInGroup(option) > 0 && countSelectedItemsInGroup(option) < getItemsInGroup(option).length"
                        class="pointer-events-none"
                        :tabindex="-1"
                      />
                      <VIcon v-if="option.icon" dense :name="option.icon" />
                      <div class="py-2 truncate">{{ option.label }}</div>
                    </button>
                  </template>
                  <template v-else>
                    <div class="px-4 pl-3 py-2 w-full text-sm leading-5 rounded-md flex gap-2 items-center whitespace-nowrap" :title="option.label">
                      <VIcon v-if="option.icon" dense :name="option.icon" />
                      <div class="truncate">{{ option.label }}</div>
                    </div>
                  </template>
                </template>
                <template v-else-if="option.type === 'separator'">
                  <hr>
                </template>
                <template v-else>
                  <button
                    class="px-4 pl-3 w-full text-sm leading-5 rounded-md flex gap-2 items-center whitespace-nowrap"
                    :title="option.label"
                    :disabled="option.disabled"
                    :class="{ 'cursor-not-allowed text-neutral-350': option.disabled, 'cursor-pointer hover:bg-primary-150 text-neutral-850': !option.disabled }"
                    @click.prevent="toggleItemsInPreset(option)"
                  >
                    <div class="py-2 truncate">{{ option.label }}</div>
                    <VIcon v-if="option.icon" class="ml-auto" dense :name="option.icon" />
                  </button>
                </template>
              </template>
              <template v-else>
                <button
                  class="px-4 pl-3 w-full text-sm leading-5 rounded-md flex gap-2 items-center whitespace-nowrap"
                  :title="option.label"
                  :disabled="option.disabled"
                  :class="{ 'bg-primary-150': props.isSelected(option), 'cursor-not-allowed text-neutral-350': option.disabled, 'cursor-pointer hover:bg-primary-150 text-neutral-850': !option.disabled }"
                  @click.prevent="select(option.value)"
                >
                  <VCheckbox v-if="props.multiple" :model-value="props.isSelected(option)" class="pointer-events-none" :tabindex="-1" />
                  <div class="py-2 truncate">{{ option.label ?? option.value }}</div>
                  <VIcon v-if="option.icon" class="ml-auto" dense :name="option.icon" />
                  <div v-else-if="option.badge" class="ml-auto rounded-full border border-neutral-500 max-w-32 text-neutral-500 flex gap-1.5 px-2.5 py-0.5 items-center justify-center" :title="option.badge.label">
                    <VIcon v-if="option.badge.icon" size="xs" :name="option.badge.icon" />
                    <div class="truncate text-xs leading-4 font-normal">
                      {{ option.badge.label }}
                    </div>
                  </div>
                </button>
              </template>
            </li>
          </template>
        </VLazyDisplayable>
      </ul>
    </VTeleport>
    <VSDescription v-if="props.description">{{ props.description }}</VSDescription>
    <VAlert v-if="validationResult" class="mt-2" dense :type="validationResult[0]" :message="validationResult[1]" />
  </div>
</template>
<script setup generic="T" lang="ts">
import { useElementId } from '../../../utils/utils';
import VSLabel from './VSLabel.vue';
import VSDescription from './VSDescription.vue';
import VInput from '../VInput.vue'
import VCheckbox from '../VCheckbox.vue';
import VIcon from '../../labels/VIcon.vue';
import VAlert from '../../dialogs/VAlert.vue';
import { computed, ref, watch } from 'vue';
import type { ValidationResult } from '@component-utils/validations';
import { useSearch, type SearchOptions } from '@component-utils/search';
import { onClickOutsideOf } from '@component-utils/focus';
import { findSelectOption, getSelectOptionsFromValues } from '../helpers/select';
import type { V } from '@component-utils/types';
import { useLocalize } from '@component-utils/localization';
import { useManualTracking } from '@component-utils/reactivity';
import VTeleport from '@component-library/utilities/VTeleport.vue';
import VLazyDisplayable from '@component-library/utilities/VLazyDisplayable.vue';
import VButton from '@component-library/buttons/VButton.vue';
import { stopAndPrevent } from '~/features/_abstract/utils/event';
import type { SharedProps } from '../helpers/types';

defineOptions({
  name: 'VSSelectBase'
})

const props = withDefaults(
  defineProps<SharedProps & {
    multiple?: boolean
    open?: boolean
    search?: boolean
    searchPlaceholder?: string
    options: V.Select.Option<T>[]
    allowEmpty?: boolean
    searchOptions?: SearchOptions
    // Diverging props
    isSelected: (option: V.Select.Item<T>) => boolean
    isAnySelected: boolean
    validationResult?: ValidationResult
  }>(),
  {
    id: undefined,
    search: false,
    searchPlaceholder: useLocalize('component-library.inputs')('default_search_placeholder'),
    searchOptions: () => ({
      fuzzy: true,
      all: false
    }),
    readonly: false,
    disabled: false,
    open: false,
    label: undefined,
    labelTooltip: undefined,
    placeholder: undefined,
    description: undefined,
    allowEmpty: undefined,
    validationResult: undefined,
    name: undefined
  }
)

const emit = defineEmits<{
  select: [value: T],
  set: [values: T[], action: 'set' | 'select' | 'unselect']
  add: [value: T]
}>()

const menuOpen = ref(false)

const buttonElement = ref<HTMLButtonElement>()
const menuElement = ref<HTMLButtonElement>()

onClickOutsideOf(
  [
    buttonElement,
    menuElement
  ],
  () => {
    menuOpen.value = false
  }
)

const id = useElementId(props.id)

const validationResult = computed(() => props.validationResult)

const options = computed(() => props.options)

const { searchModel, searchValue, searchClear, searchCollectionFlat } = useSearch(options, ['label', 'value'], menuOpen, {
  includeNestedProperty: 'options',
  exclude: (object) => 'type' in object,
  ...props.searchOptions
})

const canAdd = computed(() => {
  const value = searchValue.value as T

  return props.open && value && !findSelectOption(value, 'label', props.options) && !findSelectOption(value, 'value', props.options)
})

const select = (value: T) => {
  emit('select', value)

  menuOpen.value = props.multiple
}

const set = (values: T[], action: 'set' | 'select' | 'unselect') => {
  emit('set', values, action)

  menuOpen.value = true
}

const add = () => {
  if (!canAdd.value) return

  emit('add', searchValue.value as T)

  menuOpen.value = props.multiple

  searchClear()
}

const reset = (event: PointerEvent) => {
  stopAndPrevent(event)

  emit('set', [], 'set')
}

watch(menuOpen, (value) => {
  if (value) {
    const animationFrameCallback = () => {
      recomputePosition()

      if (menuOpen.value) {
        window.requestAnimationFrame(animationFrameCallback)
      }
    }

    window.requestAnimationFrame(animationFrameCallback)
  }
})

const getItemsInGroup = (group: V.Select.ItemGroup<T>) => {
  return group.options.reduce<V.Select.Item<T>[]>((memo, option) => {
    if ('value' in option) {
      if (!option.disabled) memo.push(option)
    } else if (option.type === 'group') memo.push(...getItemsInGroup(option))
    return memo
  }, [])
}

const countSelectedItemsInGroup = (group: V.Select.ItemGroup<T>) => {
  return getItemsInGroup(group).filter(props.isSelected).length
}

const toggleItemsInGroup = (group: V.Select.ItemGroup<T>) => {
  const options = getItemsInGroup(group).map((option) => option.value)

  if (countSelectedItemsInGroup(group) === options.length) {
    set(options, 'unselect')
  } else {
    set(options, 'select')
  }
}

const toggleItemsInPreset = (option: V.Select.ItemPreset<T>) => {
  const values = getSelectOptionsFromValues(option.values, options.value).filter((opt) => !opt.disabled).map((opt) => opt.value)

  set(values, 'set')
}

const { computedRef: position, recompute: recomputePosition } = useManualTracking(() => {
  const rect = buttonElement.value?.getBoundingClientRect()
  if (!rect) throw new Error('Element is not visible')

  // Fixed value of 300px that needs to be clear for the dropdown to display downwards
  // Realistically it is not possible to go beyond 288px
  const menuHeight = 300

  const windowHeight = window.innerHeight

  if (rect.bottom + menuHeight > windowHeight) {
    return {
      bottom: `${windowHeight - rect.top}px`,
      left: `${rect.left}px`,
      width: `${rect.width}px`
    }
  } else {
    return {
      top: `${rect.bottom}px`,
      left: `${rect.left}px`,
      width: `${rect.width}px`
    }
  }
})
</script>